import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Heading from "../../Heading/Heading";
import PostCTA from "../../Blog/PostCTA/PostCTA";
import { Helmet } from "react-helmet";
import { debounce } from "lodash";
import { sanitizePostContent } from "../../../helpers/helpers";

import "./PostTemplate.scss";

/**
 *
 * @param {{ __html: string }}
 * @returns {{ __html: string }}
 */
function wrapIframe({ __html: string }) {
	const iframeStart = /<iframe.*src="https:\/\/(www)?.youtube.com\/embed\//;
	const iframeEnd = /src="https:\/\/(www)?.youtube.com\/embed\/.*<\/iframe>/;

	const videoCodeStart = string.match(iframeStart)?.[0];
	const videoCodeEnd = string.match(iframeEnd)?.[0];

	if (!videoCodeStart || !videoCodeEnd) return { __html: string };

	const contentStart = string.indexOf(videoCodeStart);
	const contentEnd = string.indexOf(videoCodeEnd);

	const iframeCode = string.slice(contentStart, contentEnd + videoCodeEnd.length);

	const wrappedCode = `<div class="embed-video-container"><div class="embed-video-wrapper">${iframeCode}</div></div>`;

	return { __html: string.replace(iframeCode, wrappedCode) };
}

const PostTemplate = ({
	feature_image,
	title,
	html,
	codeinjection_head,
	toggleModal,
	children,
}) => {
	const [showBanner, setShowBanner] = useState(false);
	const [images, setImages] = useState([]);

	const postContentRef = useRef(null);

	useEffect(() => {
		const checkScroll = () => {
			if (!showBanner && window.pageYOffset > 400) {
				return setShowBanner(true);
			}

			return setShowBanner(false);
		};

		window.addEventListener(`scroll`, debounce(checkScroll, 50));

		() => window.removeEventListener(`scroll`, checkScroll);
	}, []);

	useEffect(() => {
		if (postContentRef.current) {
			const imgs = postContentRef.current.querySelectorAll(".kg-image-card");

			setImages(Array.from(imgs));
		}
	}, []);

	const toggleImagePreview = image => {
		image.classList.toggle("post__content--image-preview");
	};

	useEffect(() => {
		images.forEach(image => {
			return (image.onclick = () => toggleImagePreview(image));
		});
	}, [images]);

	const hideImagesOnScrollOrOutsideClick = (e, forceEvent = false) => {
		const activeImages = postContentRef.current?.querySelectorAll(
			".kg-image-card.post__content--image-preview > img",
		);

		Array.from(activeImages).forEach(image => {
			if (!e.target.contains(image) && !forceEvent) {
				return image.parentNode.classList.remove("post__content--image-preview");
			}
			if (forceEvent) {
				return image.parentNode.classList.remove("post__content--image-preview");
			}
		});
	};

	useEffect(() => {
		window.addEventListener("click", hideImagesOnScrollOrOutsideClick);
		window.addEventListener("scroll", e => hideImagesOnScrollOrOutsideClick(e, true));
		window.addEventListener("touch", hideImagesOnScrollOrOutsideClick);

		return () => {
			window.removeEventListener("click", hideImagesOnScrollOrOutsideClick);
			window.removeEventListener("scroll", e => hideImagesOnScrollOrOutsideClick(e, true));
			window.removeEventListener("touch", hideImagesOnScrollOrOutsideClick);
		};
	}, []);

	return (
		<article className="post">
			<Helmet>
				<script type="text/javascript" async defer>
					{codeinjection_head}
				</script>
				<body className="no-overflow" />
			</Helmet>
			{children}
			<figure className="post__featureImage">
				<img className="post__featureImage--image" src={feature_image} alt={title} title={title} />
			</figure>
			<section className="post__container">
				<Heading type={1} className="post__title">
					{title}
				</Heading>
				<section
					className="post__content"
					ref={postContentRef}
					dangerouslySetInnerHTML={wrapIframe(sanitizePostContent(html))}
				/>
				{/* leaving the code as an example for possible future needs (as docs :D)  */}
				{/* {locale === "pl" && (
					<aside className="post__content--banner">
						{showBanner && (
							<a
								a
								href="https://www.botwise.io/raport2020?utm_source=botwise&utm_medium=baner&utm_campaign=raport2020"
								target="_blank"
								rel="noreferrer noopener"
							>
								<SideCCBannerSVG className="cta-banner" />
							</a>
						)}
					</aside>
				)} */}
				<PostCTA toggleModal={toggleModal} />
			</section>
		</article>
	);
};

PostTemplate.propTypes = {
	feature_image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	html: PropTypes.string.isRequired,
	toggleModal: PropTypes.func.isRequired,
	codeinjection_head: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

PostTemplate.defaultProps = {
	codeinjection_head: "",
};

export default PostTemplate;
