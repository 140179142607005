import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../../../../components/Layout/Layout";

import PostTemplate from "../../../../components/Blog/PostTemplate/PostTemplate";
import { MetaData } from "../../../../components/Blog/meta";

const Post = ({ data, location, pageContext }) => {
	const {
		ghostPost: { feature_image, title, html },
		allGhostSettings,
	} = data;

	const site = allGhostSettings.edges[0].node;

	return (
		<>
			<Layout pageContext={pageContext} showScrollArrow>
				<PostTemplate
					feature_image={feature_image}
					title={title}
					html={html}
					codeinjection_head={site.codeinjection_head}
				>
					<MetaData data={data} location={location} type="article" />
				</PostTemplate>
			</Layout>
		</>
	);
};

Post.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
	data: PropTypes.shape({
		ghostPost: PropTypes.shape({
			title: PropTypes.string.isRequired,
			html: PropTypes.string.isRequired,
			feature_image: PropTypes.string,
		}).isRequired,
		allGhostSettings: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({ node: PropTypes.shape({ codeinjection_head: PropTypes.string }) }),
			).isRequired,
		}).isRequired,
	}).isRequired,
	location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
	query($slug: String!) {
		ghostPost(slug: { eq: $slug }) {
			...GhostPostFields
		}
		allGhostSettings {
			edges {
				node {
					codeinjection_head
				}
			}
		}
	}
`;
