import React from "react";
import PropTypes from "prop-types";
import Button from "../../Button/Button";
import useTranslations from "../../../hoc/useTranslations";

import OvalSVG from "../../../assets/icons/path_oval.svg";

import "./PostCTA.scss";

const PostCTA = ({ toggleModal }) => {
	const {
		blog: {
			post_cta: { title, description },
		},
		getDemoButton,
	} = useTranslations();
	return (
		<aside className="post-cta-section">
			<OvalSVG className="post-cta-section-oval-top" />
			<h2 className="post-cta-section-heading">{title}</h2>
			<p className="post-cta-section-paragraph">{description}</p>
			<Button onClick={toggleModal} classNames={["post-cta-section-button"]}>
				{getDemoButton}
			</Button>
			<OvalSVG className="post-cta-section-oval-bottom" />
		</aside>
	);
};

PostCTA.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default PostCTA;
